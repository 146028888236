import * as GQL from "@/gql";
import * as Icons from "@/icons";
import { GetStaticProps } from "next";
import Head from "next/head";
import Link from "next/link";
import { useRouter } from "next/router";
import React from "react";
import * as TUI from "theme-ui";
import { LayoutSection } from "../components";
import { Button } from "../components/Button";
import { Footer } from "../components/Footer";
import { Header } from "../components/Header";
import { Markdown } from "../components/Markdown";
import { fetchCMS } from "../lib/graphql/api";
import { homeLinkProps } from "../lib/links";

interface Props {
  notFoundPage: NonNullable<GQL.NotFoundPageQuery["notFoundPage"]>;
}

function NotFoundPage(props: Props) {
  const notFoundPage = (() => {
    const { notFoundPage } = props;

    /*
     * There's a bug in Next.js where combination of middleware + getStaticProps
     * in the 404 page causes the props to be an empty object during the second
     * render pass in the browser. To avoid crashing, we store the
     * 'notFoundPage' on window, and use that if available.
     */
    if (typeof window !== "undefined") {
      const win = window as any as Props;
      if (notFoundPage) {
        win.notFoundPage = notFoundPage;
      }

      return win.notFoundPage ?? notFoundPage;
    }

    return notFoundPage;
  })();

  const router = useRouter();

  React.useEffect(() => {
    if (typeof dataLayer !== "undefined") {
      dataLayer.push({
        event: "Page Not Found",
        path: router.asPath,
        locale: router.locale,
      });
    }
  }, [router]);

  const content = notFoundPage?._allContentLocales?.find((x) => x?.locale === router.locale);
  const callToAction = notFoundPage?._allCallToActionLocales?.find((x) => x?.locale === router.locale);

  return (
    <TUI.Box>
      <Header />

      <Head>
        <title>404</title>
      </Head>

      <LayoutSection sx={{ bg: "white", pb: 6 }}>
        <TUI.Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            mb: [6, 7],
          }}
        >
          <TUI.Box>
            <picture>
              <TUI.Image
                width="880"
                height="584"
                sx={{ maxWidth: "100%", mt: 3, mb: 3 }}
                src={require("../../assets/page_not_found/illustration_l.svg")}
              />
            </picture>
          </TUI.Box>

          <TUI.Text variant="f5">
            <Markdown variant="unstyled">{content?.value}</Markdown>
          </TUI.Text>

          <TUI.Box sx={{ mt: 4 }}>
            <Link passHref {...homeLinkProps} legacyBehavior>
              <Button as="a" variant="accent" endIcon={<Icons.ArrowRight24 />}>
                {callToAction?.value}
              </Button>
            </Link>
          </TUI.Box>
        </TUI.Box>
      </LayoutSection>

      <Footer />
    </TUI.Box>
  );
}

export default NotFoundPage;

export const getStaticProps: GetStaticProps<Props, {}> = async (context) => {
  const result = await fetchCMS<GQL.NotFoundPageQuery>(GQL.NotFoundPageDocument, context);

  return {
    props: {
      notFoundPage: result.notFoundPage!,
    },
  };
};
